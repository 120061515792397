/**
 * Vruchtvlees © 2007-2019
 *
 * Hi, welcome!
 *
 * This is the main Javscript file.
 * One script to rule them all!
 * Let's go and godspeed you!
 */

import anime from 'animejs';
import $ from 'jquery';
import jQuery from 'jquery';
import axios from 'axios';

/** ----------------------------------------
    Webpack Settings
 ---------------------------------------- */

let webpack = SETTINGS;

/** ----------------------------------------
    Environment
 ---------------------------------------- */

/**
 * This class will check the current
 * environment of the website and stores
 * an object with information about the
 * current environment.
 */

import { Environment } from '@helper/Environment.js';
import { Log } from '@helper/Log.js';

const host = new Environment({
    localhost: window.location.origin,
    preview: 'http://nob.dev.vruchtvlees.com',
    webpack: webpack
});

const log = new Log(host);

if(!host.info.environment.live) {
    log.section('VV', 'Environment');
    host.log();
}

/** ----------------------------------------
    Themes Toggle
 ---------------------------------------- */

/**
 * This section will set a toggle within
 * the DOM to switch between multiple
 * themes, the class only initiates if there
 * is more than one available theme declared
 * within the Webpack settings.
 */

// import { ThemesToggle } from '@js/style/Theme.js';
// new ThemesToggle(webpack);

/** ----------------------------------------
    Set Style Guide
 ---------------------------------------- */

/**
 * This section will set the VV Style Guide
 * as a default for at the index.html.
 *
 * You can mess around with the information
 * it will be displayed within the page.
 */

// import { Style } from '@js/style/Style.js';

let info = {
    title: 'Vruchtvlees Digital Style Guide',
    intro: 'This style guide provides guidelines for the digital design as configured within the SASS stylesheets. It will automatically extend and change once you start working on the project. If certain elements or blocks should be left out these can be configured within the config.js file related to the style guide. It serves as a guide to quickly check what variables have been configured, what names they use and how the styling of a certain element has been affected within the stylesheet. Godspeed you!',
};

let config = {
    breakpoints: true,
    grid: true,
    typo: true,
    letters: true,
    colors: true,
    headings: true,
    text: true,
    content: true,
    font: true,
    links: true,
    tooltip: true,
    forms: true,
    lists: true,
    tables: true,
    buttons: true,
    icons: true
};

// const style = new Style(info, config, true);

if(!host.info.environment.live) {
    log.section('VV', 'Style');
    // style.log();
}

/** ----------------------------------------
    Responsive Images
 ---------------------------------------- */

import { ResponsiveImages } from '@module/ResponsiveImages.js';

// let responsive = new ResponsiveImages(style);

if(!host.info.environment.live) {
    log.section('VV', 'Responsive');
    // responsive.log();
}

/* ----------------------------------------
    Vue
---------------------------------------- */

import Vue from 'vue';

/* ----------------------------------------
    Results Component
---------------------------------------- */

import Results from './vue/Results.vue';

const results = document.querySelector('#results');

if(results) {
    new Vue({
        el: '#results',
        data: JSON.parse(results.dataset.theme, false),
        render: h => h(Results),
    });
}

/* ----------------------------------------
    Thread Component
---------------------------------------- */

import Threads from './vue/Threads.vue';

if(document.querySelector('#threads')) {
    new Vue({
        el: '#threads',
        render: h => h(Threads)
    });
}

/* ----------------------------------------
    Schools Component
---------------------------------------- */

import Schools from './vue/Schools.vue';

if(document.querySelector('#schools')) {
    new Vue({
        el: '#schools',
        render: h => h(Schools)
    });
}

/* ----------------------------------------
    Comments Component
---------------------------------------- */

import Comments from './vue/Comments.vue';

if(document.querySelector('#comments')) {
    new Vue({
        el: '#comments',
        render: h => h(Comments)
    });
}

/** ----------------------------------------
     Toast
 ---------------------------------------- */

window.addEventListener('load', () => {
    const toast = document.querySelector('.toast');
    if (toast) {
        setTimeout(() => {
            anime({
                targets: toast,
                height: 0,
                easing: 'easeOutQuad',
                delay: 20000,
                duration: 250,
                complete: () => {
                    toast.remove();
                }
            });
        }, 2500);
    }
});

/** ----------------------------------------
    Slider
 ---------------------------------------- */

import slick from 'slick-carousel';

window.addEventListener('load', () => {

    /** ----------------------------------------
         Highlight Slider
     ---------------------------------------- */

    $('#js-slider-highlight').on('init', (event, slick) => {
        slick.$slider.addClass('is-active');
    });

    if ($('#js-slider-highlight')) {
        $('#js-slider-highlight').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            adaptiveHeight: true,
            arrows: false,
            dots: true,
            appendDots: $('.slider__dots'),
            fade: true,
            autoplay: false
        });
    }

    /** ----------------------------------------
         Slider block
     ---------------------------------------- */

    if ($('.js-slider-block')) {
        $('.js-slider-block').each(function() {
            let $this = $(this);

            $this.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                dots: false,
                fade: true,
                prevArrow: $this.parent().find('.gallery-block__arrow--left'),
                nextArrow: $this.parent().find('.gallery-block__arrow--right')
            });
        });

    }
});

/** ----------------------------------------
    Advice
 ---------------------------------------- */

window.addEventListener('load', () => {
    const advice = document.body.querySelector('#js-advice');

    if(!advice) return;

    const adviceAvatar = advice.querySelector('.advice__avatar');
    const adviceTip = advice.querySelector('.advice__tip');

    if(!adviceAvatar) return;
    if(!adviceTip) return;

    adviceAvatar.addEventListener('click', () => {
        if (adviceTip.classList.contains('active')) {
            adviceTip.classList.remove('active');
        } else {
            adviceTip.classList.add('active');
        }
    });
});

/** ----------------------------------------
    Select
 ---------------------------------------- */

import { Select } from '@module/Select';

const selectElems = document.querySelectorAll('.select');

for (let selectEl of selectElems) {
    new Select(selectEl, host);
}

/** ----------------------------------------
    Enlarge Image
 ---------------------------------------- */

import { EnlargeImage } from './module/EnlargeImage';

const images = document.querySelectorAll('.js-enlarge');

for (let image of images) {
    if(image) new EnlargeImage(image);
}

/** ----------------------------------------
    Gallery Block
 ---------------------------------------- */

window.addEventListener('load', () => {
    $('.gallery-block__slides').each(() => {
        const $leftArrow = $('.slider__arrows--left');
        const $rightArrow = $('.slider__arrows--right');

        $(this).slick({
            autoplay: true,
            autoplaySpeed: 7500,
            pauseOnHover: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            prevArrow: $leftArrow,
            nextArrow: $rightArrow,
            dots: false,
            speed: 750,
            cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
            infinite: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        cssEase: 'ease',
                    }
                }
            ]
        });
    });
});

/** ----------------------------------------
     Dashboard Calendar
 ---------------------------------------- */

window.addEventListener('load', () => {
    const $leftArrow = $('.calendar__arrow--left');
    const $rightArrow = $('.calendar__arrow--right');

    $('.calendar__slider').slick({
        autoplay: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: $leftArrow,
        nextArrow: $rightArrow,
        dots: false,
        speed: 250,
        fade: true,
        infinite: false,
    });

    $('.calendar__slider').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        const $next = slick.$slides[nextSlide];
        const $page = $($next).find('.calendar__items').data('page');
        const $total = $($next).find('.calendar__items').data('total');
        $('.calendar__nav').find('.calendar__paginate').text(`${$page} / ${$total}`);
    });
});

/** ----------------------------------------
     Like Button
 ---------------------------------------- */

import { Like } from '@module/Like';

window.addEventListener('load', () => {
    for (let el of document.querySelectorAll('.js-like')) {
        new Like(el);
    }
});

/** ----------------------------------------
    Accordion
 ---------------------------------------- */

import { Accordion } from '@module/Accordion';

const accordions = document.querySelectorAll('.accordion-block__holder');

for (let accordion of accordions) {
    if(accordion)
        new Accordion({
            holder: accordion,
            trigger: '.accordion-block__question',
            target: '.accordion-block__content'
        });
}

/** ----------------------------------------
    Tabs
 ---------------------------------------- */

import { Tabs } from '@module/Tabs';

for (let tabsEl of document.querySelectorAll('.js-tabs')) {
    new Tabs(tabsEl);
}

/** ----------------------------------------
    Confirm
 ---------------------------------------- */

import { Confirm } from '@module/Confirm';

for (let confirmEl of document.querySelectorAll('.confirm')) {
    new Confirm(confirmEl);
}

/** ----------------------------------------
     Popup
 ---------------------------------------- */

import { Popup } from './module/Popup';

/** ----------------------------------------
    Dashboard Configuration
 ---------------------------------------- */

const dashboardConfiguration = `
    <h1 class="popup__title">Hello world</h1>
    <p class="popup__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore maxime nostrum sint tempora tenetur? Deserunt molestiae quis totam!</p>
`;

new Popup({
    popup: '#js-popup',
    open: '#js-open',
    html: dashboardConfiguration,
    enlarge: true
});

/** ----------------------------------------
     Popup Random
 ---------------------------------------- */

new Popup({
    popup: '#js-popup',
    open: '#js-popup-data'
});

/** ----------------------------------------
    Popup New Post
 ---------------------------------------- */

new Popup({
    popup: '#js-popup-new-post',
    open: '#js-new-post'
});

new Popup({
    popup: '#js-popup-edit-post',
    open: '#js-edit-post'
});

new Popup({
    popup: '#js-popup-new-post',
    open: '#js-note-new-post'
});

/** ----------------------------------------
    Popup Community Disclaimer
 ---------------------------------------- */

new Popup({
    popup: '#js-popup-disclaimer',
    open: '#js-disclaimer'
});

/** ----------------------------------------
    Popup Password
 ---------------------------------------- */

new Popup({
    popup: '#js-popup-password-change',
    open: '#js-password-change'
});

/** ----------------------------------------
    Popup Employee
 ---------------------------------------- */

new Popup({
    popup: '#js-popup-employee-add',
    open: '#js-employee-add'
});

['delete', 'edit', 'accept', 'decline'].forEach(action => {
    const triggers = document.querySelectorAll(`.js-employee-${ action }`);
    [...triggers].forEach(element => {
        new Popup({
            popup: `#js-popup-employee-${ action }-${ element.dataset.id }`,
            open: `#${ element.id }`
        });
    });
});

/** ----------------------------------------
     Popup Subsidy Status
 ---------------------------------------- */

new Popup({
    popup: '#js-popup-subsidy-status',
    open: '#js-subsidy-status'
});

/** ----------------------------------------
    Sticky Navigation
 ---------------------------------------- */

import { StickyNavigation } from '@js/module/StickyNavigation';
new StickyNavigation();

/** ----------------------------------------
    Mobile Menu
 ---------------------------------------- */

import { MobileMenu } from '@js/module/MobileMenu';
new MobileMenu();

/** ----------------------------------------
     Search
 ---------------------------------------- */

import { Search } from '@js/module/Search';
new Search();

/** ----------------------------------------
    Nav dropdown
 ---------------------------------------- */

import { NavDropdown } from '@js/module/NavDropdown';

new NavDropdown({
    wrapper: '.nav__note',
    el: '.notification-dropdown',
    trigger: '.notification-trigger',
});

new NavDropdown({
    wrapper: '.nav__bookmark',
    el: '.bookmarks-dropdown',
    trigger: '.bookmarks-trigger',
});

new NavDropdown({
    wrapper: '.nav__user',
    el: '.user-dropdown',
    trigger: '.user-trigger',
});

new NavDropdown({
    wrapper: '.dropdown-item-voor-leraren',
    el: '.dropdown-menu-voor-leraren',
    trigger: '.dropdown-trigger-voor-leraren',
});

new NavDropdown({
    wrapper: '.dropdown-item-voor-schoolleiders',
    el: '.dropdown-menu-voor-schoolleiders',
    trigger: '.dropdown-trigger-voor-schoolleiders',
});

new NavDropdown({
    wrapper: '.dropdown-item-voor-bestuurders',
    el: '.dropdown-menu-voor-bestuurders',
    trigger: '.dropdown-trigger-voor-bestuurders',
});

new NavDropdown({
    wrapper: '.dropdown-item-professionalisering',
    el: '.dropdown-menu-professionalisering',
    trigger: '.dropdown-trigger-professionalisering',
});

// Code to close bookmarkDropdown / notifications if you
// click outside of them.
// We need to set execptions that will not close the menus.
// All other clicks will close them.
let exceptions = [
    document.querySelector('.advice__tip'),
    document.querySelector('#js-advice'),
];

let exceptionClicked = false;

exceptions.forEach(exception => {
    if (!exception) return;
    exception.addEventListener('click', function () {
        exceptionClicked = true;
    }, false);
});

document.addEventListener('click', function () {
    let adviceTip = document.querySelector('.advice__tip');

    if (exceptionClicked) {
        exceptionClicked = false;
        return;
    }

    if (!adviceTip) return;

    adviceTip.classList.remove('active');
}, false);

/** ----------------------------------------
    Bookmarks dropdown delete element
 ---------------------------------------- */

let mobileDropdown = document.querySelectorAll('.mobile-dropdown');

mobileDropdown.forEach(dropdown => {
    dropdown.addEventListener('click', function (event) {
        event.preventDefault();
        dropdown.classList.toggle('is-open');
        dropdown.nextElementSibling.classList.toggle('is-open');
    }, false);
});

/** ----------------------------------------
    Bookmarks dropdown delete element
 ---------------------------------------- */

let closeBookmarkBtns = document.querySelectorAll('.js-close-bookmark');

closeBookmarkBtns.forEach(btn => {
    btn.addEventListener('click', function (event) {
        event.preventDefault();
        window.location.href = btn.getAttribute('href');
    }, false);
});

/** ----------------------------------------
    Notifications
 ---------------------------------------- */

import { Notification } from '@js/module/Notification';

const notificationElems = document.querySelectorAll('.notification');
const notifications = [];

[...notificationElems].forEach(el => {
    notifications.push(new Notification(el));
});

const showReadNotifications = document.querySelector('#js-show-read-notifications');

if (showReadNotifications) {
    showReadNotifications.addEventListener('click', () => {
        const readNotifications = document.querySelector('#js-read-notifications');
        readNotifications.classList.add('show');
        showReadNotifications.remove();
    });
}

let notificationCloseAllElems = document.querySelectorAll('.js-notification-close-all');

[...notificationCloseAllElems].forEach(el => {
    el.addEventListener('click', e => {
        e.preventDefault();

        if (el.clicked) return;
        el.clicked = true;

        const endpoint = el.href;
        const relatedNotificationElems = Array.from(el.parentElement.querySelectorAll('.notification'));

        axios.get(endpoint, auth(endpoint).headers).then(() => {
            notifications
                .filter(notification => notification.closeEl && relatedNotificationElems.includes(notification.el))
                .forEach(notification => {
                    notification.close();
                });
        });
    });
});

/** ----------------------------------------
 Add New Thread Modal
 ---------------------------------------- */

import { AddThread } from '@js/module/AddThread';
new AddThread();

/** ----------------------------------------
    Subscribe
 ---------------------------------------- */

const subscribe = document.querySelectorAll('[data-submit]');

[...subscribe].map(submit => {
    const text = submit.dataset.submit;
    const endpoint = submit.dataset.endpoint;
    const action = (submit.dataset.action) ? submit.dataset.action : 'post';

    submit.addEventListener('click', function submitListener(e) {
        e.preventDefault();

        const content = submit.querySelector('span');
        submit.classList.add('is-submitted');
        content.innerText = text;

        let fetch = auth(endpoint);

        if (action === 'delete') {
            axios.delete(endpoint, fetch.headers).then();
        } else {
            axios.post(endpoint, {}, fetch.headers).then();
        }

        submit.removeEventListener('click', submitListener);
    });
});

/** ----------------------------------------
    Social Share
 ---------------------------------------- */

window.addEventListener('load', () => {
    const share = document.querySelector('.share');
    if(!share) return;

    const items = share.querySelectorAll('div');

    [...items].map(item => {
        item.addEventListener('click', () => {
            if(item.classList.contains('activate'))
                item.classList.add('is-active');
        });
    });
});

import SocialShare from './module/SocialShare';

const share = document.querySelectorAll('[data-module="social"]');
[...share].map(el => el && new SocialShare(el));

/** ----------------------------------------
     Edit
 ---------------------------------------- */

import FileInput from './module/FileInput';

const fileInputs = [];
const fileElems = document.querySelectorAll('.js-file');
[...fileElems].forEach(el => fileInputs.push(new FileInput(el)));

/** ----------------------------------------
     Validate form file sizes before uploading
 ---------------------------------------- */

import { validateFormFiles } from './helpers/Helpers.js';

let forms = document.querySelectorAll('form.js-validate-files');

[...forms].forEach(form => {
    form.addEventListener('submit', e => {
        (validateFormFiles(form)) ? form.submit() : e.preventDefault();
    });
});

/** ----------------------------------------
     Tooltips
 ---------------------------------------- */

import Tooltip from './module/Tooltip';

const tooltipElems = document.querySelectorAll('.js-tooltip');

[...tooltipElems].forEach(el => {
    new Tooltip({
        el: el,
        target: '.tooltip__target',
        trigger: '.tooltip__trigger',
        content: '.tooltip__content',
    });
});

/** ----------------------------------------
     Edit
 ---------------------------------------- */

import { EditForm } from '@js/module/EditForm';

for (const editEl of document.querySelectorAll('.edit')) {
    new EditForm(editEl);
}

/** ----------------------------------------
     ProfileForm
 ---------------------------------------- */

import { ProfileForm } from '@js/module/ProfileForm';

new ProfileForm();

/** ----------------------------------------
     SortStudentData
 ---------------------------------------- */

import { SortStudentData } from '@js/module/SortStudentData';

new SortStudentData();

/** ----------------------------------------
    News Overview Loader
 ---------------------------------------- */

import { NewsOverviewLoader } from '@js/module/NewsOverviewLoader';
new NewsOverviewLoader();

/** ----------------------------------------
    Search Overview Loader
 ---------------------------------------- */

import { LoadMore } from '@js/module/LoadMore';

let loadMoreElems = document.querySelectorAll('.js-load-more');

loadMoreElems.forEach(loadMoreEl => {
    new LoadMore(loadMoreEl);
});

/** ----------------------------------------
    Soundcloud
 ---------------------------------------- */

const embedSoundcloud = (block) => {
    const settings = {
        'async': true,
        'crossDomain': true,
        'url': 'https://soundcloud.com/oembed',
        'method': 'POST',
        'headers': {},
        'data': {
            'format': 'json',
            'url': block.getAttribute('soundcloud-url'),
            'color': '1E3582',
            'show_comments': false
        }
    };

    $.ajax(settings).done(function (response) {
        if (!response.html) {
            return;
        }
        block.innerHTML = response.html;
    });
};

let soundcloudBlocks = document.querySelectorAll('.js-soundcloud');

soundcloudBlocks.forEach(block => {
    embedSoundcloud(block);
});

/** ----------------------------------------
    Main Container Height
 ---------------------------------------- */

const setMainHeight = () => {
    let main = document.querySelector('.page-content');
    let header = document.querySelector('.nav');
    let footer = document.querySelector('.footer');
    if (main && header && footer) {
        main.style.minHeight = `calc(100vh - ${ header.offsetHeight + footer.offsetHeight - 1 }px)`;
    }
};

window.onload = () => {
    setMainHeight();
};

/** ----------------------------------------
    Tile Heights
 ---------------------------------------- */

const fixTileHeights = (targetClass) => {
    const grids = document.querySelectorAll('.js-fix-tile-height');

    grids.forEach(grid => {
        let targets = grid.querySelectorAll(targetClass);
        let heights = [];

        targets.forEach(target => {
            heights.push(target.offsetHeight);
        });

        let maxHeight = Math.max(...heights);

        targets.forEach(target => {
            if (maxHeight != 0){
                target.style.minHeight = `${maxHeight}px`;
            }
        });
    });
};

window.onload = () => {
    fixTileHeights('.overview-card');
    fixTileHeights('.grid__item--large');
};

/** ----------------------------------------
    NTC Target
 ---------------------------------------- */

const ntcReadmore = document.querySelectorAll('.ntc-target__readmore');

if(ntcReadmore) {
    ntcReadmore.forEach(btn => {
        btn.addEventListener('click', function () {
            btn.classList.toggle('is-open');
        });
    });
}

/** ----------------------------------------
    Livewire popup
 ---------------------------------------- */

const popupTrigger = document.querySelectorAll('.livewire-popup');

if(popupTrigger){
    popupTrigger.forEach(function (trigger) {
        trigger.addEventListener('click', function (e) {
            window.livewire.emit('openPopup', e.target.getAttribute('data-payload'));
        });
    });
}

/** ----------------------------------------
    Show hide elements
 ---------------------------------------- */

[...document.querySelectorAll('.js-show-element')].forEach(el => {
    el.addEventListener('click', () => {
        [...document.querySelectorAll(el.dataset.show)].forEach(targetEl => targetEl.classList.remove('hidden'));
    });
});

[...document.querySelectorAll('.js-hide-element')].forEach(el => {
    el.addEventListener('click', () => {
        [...document.querySelectorAll(el.dataset.hide)].forEach(targetEl => targetEl.classList.add('hidden'));
    });
});


/** ----------------------------------------
    Show drag button
 ---------------------------------------- */

const checkboxes = document.querySelectorAll('.confirm-terms');
const confirmSubsidy = document.getElementById('confirm-subsidy-slider');

function checkCheckboxes() {
    const allChecked = [...checkboxes].every(checkbox => checkbox.checked);
    if (allChecked) {
        confirmSubsidy.style.display = '';
    } else {
        confirmSubsidy.style.display = 'none';
    }
}

checkboxes.forEach(checkbox => checkbox.addEventListener('change', checkCheckboxes));

/** ----------------------------------------
    Drag button
 ---------------------------------------- */

const dragSlider = document.querySelector('.js-drag-slider');
const dragBackground = document.querySelector('.js-confirm-subsidy-background');
const dragText = document.querySelector('.js-drag-text');
const dragSubmit = document.querySelector('.js-subsidy-commit');
if (dragSlider) {

    dragSlider.oninput = function () {
        if (dragSlider.value > 5) {
            // console.log('past 5');
            dragBackground.classList.add('subsidy-pending');
            dragText.innerHTML = '...Bevestigen';
        }
    };

    dragSlider.addEventListener('mouseup', function () {
        if (dragSlider.value < 85) {
            dragSlider.value = 0;
        }
        if (dragSlider.value < 5) {
            dragBackground.classList.remove('subsidy-pending');
            dragText.innerHTML = 'Schuif om te bevestigen';
        }
        if (dragSlider.value >= 85) {
            dragBackground.classList.add('subsidy-succes');
            dragText.innerHTML = 'Gegevens bevestigd!';
            dragSlider.disabled = true;
            dragSubmit.style = '';

        }
    });
}

/** ----------------------------------------
    Drag Mobile button
 ---------------------------------------- */
const dragSliderMobile = document.querySelector('.js-confirm-subsidy-mobile');
const dragBackgroundMobile = document.querySelector('.js-confirm-subsidy-background-mobile');
const dragTextMobile = document.querySelector('.js-drag-text-mobile');
if (dragSliderMobile) {
    dragSliderMobile.addEventListener('click', function () {
        dragBackgroundMobile.classList.add('subsidy-succes');
        dragTextMobile.innerHTML = 'Gegevens bevestigd!';
        dragSlider.disabled = true;
        dragSubmit.style = '';
    });
}
/** ----------------------------------------
    Easter Egg
 ---------------------------------------- */

/**
 * Easter comes early this year, enjoy!
 */

import { Confetti } from '@js/style/easter/Confetti';
new Confetti();
